.login-image {

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 400px!important;
}

.front-slide {
  z-index: 999;
}

.paper {
  margin: 20px 0px 0px 0px;
  display: 'flex';
  flex-direction: 'column';
  align-items: 'center';

}

.paper-height {
  height: 500px;
}

.form-group {
  flex: 0 0 auto;
  flex-flow: row wrap;
  align-items: center;
  margin-bottom: 1rem;
  margin-left: 10%;
  margin-right: 10%;
}

.start-line {
  text-align: start;
  margin-bottom: 1rem;
}

/*
====================================
=== ANIMAZIONE IMMAGINI DI LOGIN ===
====================================
*/

@keyframes fade {
  0% {
    opacity: 0;
  }
  11.11% {
    opacity: 1;
  }
  33.33% {
    opacity: 1;
  }
  44.44% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.staticImg {
  position: relative;
  height: 329px;
  width: 100%;
  margin: 0 auto;
}

.fadein {
  position: relative;
  height: 329px;
  width: 100%;
  margin: 0 auto;
}
.fadein img {
  position: absolute;
  left: 0;
  right: 0;
  opacity: 0;
  animation-name: fade;
  animation-duration: 50s;
  animation-iteration-count: infinite;

}
.fadein img:nth-child(1) {
  animation-delay: 0s;
}
.fadein img:nth-child(2) {
  animation-delay: 11s;
}
.fadein img:nth-child(3) {
  animation-delay: 22s;
}
.fadein img:nth-child(4) {
  animation-delay: 33s;
}


.spaghetto-container {
	height: -moz-fit-content;
	height: fit-content;
	width: 100%;
	background-color: #FFF;
	/* bottom: 0px !important;
	position: absolute;
	box-shadow: 0px -4px 3px rgba(50, 50, 50, 0.15); */
}

#icons-container {
  display: flex;
  position: relative;
  justify-content: space-evenly;
}

/* .icon {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 1px solid white;
  margin-right: -15px;
} */
.icon {
	height: 40px;
	width: 100%;
	/* border: 1px solid white; */
  margin: 2px;
}
.icon-master {
	height: 60px;
	width: 400px;
	/* border: 1px solid white; */
}
.single-icon-container {
	height: 45px;
}
