@import url(https://fonts.googleapis.com/css?family=Raleway:400,500);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,500);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* stile applicato a tutte le label */

.lbl {
  font-size: 1em;
  font-weight: 500;
}

/*
da utilizzare per le label da nascondere
senza perdere la compatibilità WCAG. fonte:
https://www.w3.org/WAI/tutorials/forms/labels/#note-on-hiding-elements
*/

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}


.heading {
	font-size: 16px;
	text-align: center;
	padding: 0px 0 70px 0;
}
.gallery {
	display: flex;
	flex-wrap: wrap;
	/* Compensate for excess margin on outer gallery flex items */
	margin: -1rem -1rem;
	padding-bottom: 30px;
	width: 90%;
}

.gallery-image {
	display: block;
	width: 100%;
	height: 230px;
	object-fit: cover;
	transition: -webkit-transform 200ms ease-out;
	transition: transform 200ms ease-out;
	transition: transform 200ms ease-out, -webkit-transform 200ms ease-out;
	cursor:pointer;

}

.gallery-image:hover {
	/* transform: scale(1.65); */
}

.gallery-item {
	/* Minimum width of 24rem and grow to fit available space */
	flex: 1 0 28rem;
	/* Margin value should be half of grid-gap value as margins on flex items don't collapse */
	margin: 1rem;
	box-shadow: 0.3rem 0.4rem 0.4rem rgba(0, 0, 0, 0.4);
	overflow: hidden;

}

.title {
	position: static;
	max-width: 285px;
	height: auto;
	text-transform: uppercase;
	font-weight: bold;
	text-overflow: ellipsis;
	overflow: hidden;
	text-align: left;
	white-space: nowrap;
}

div.disabled
{
	pointer-events: none;
	/* for "disabled" effect */
	opacity: 0.3;
	/* background: #000; */
}


/*
==========================animaz mattonelle================================
h t t p s : / / c o d e p e n . i o / j o s h h u n t / p e n / L V Q Z R a
===========================================================================
*/


.contain {
	width: 100%;
	align-content: center;
	display: flex;
	text-align: center;
}

.row {
	width: 100%;
	margin-top: -80px;
	height: 380px;
	overflow-block: hidden;
}



.row2 {
	width: 100%;
	margin-top: -80px;
	height: 380px;
	/* overflow-block: hidden; */
}


.row__inner {
	transition: 150ms -webkit-transform;
	transition: 150ms transform;
	transition: 150ms transform, 150ms -webkit-transform;
	font-size: 0;
	white-space: nowrap;
	margin: 58.3125px 0;
	padding-bottom: 10px;
}

/* .tile-main {
	position: relative;
	display: inline-block;
	width: 250px;
	height: 190.625px;
	margin-right: 10px;
	font-size: 20px;
	cursor: pointer;
	transition: 150ms all;
	transform-origin: center left;
	margin: 1rem;
} */

.tile {
	position: relative;
	display: inline-block;
	width: 250px;
	height: 190.625px;
	margin-right: 10px;
	font-size: 20px;
	cursor: pointer;
	transition: 150ms all;
	-webkit-transform-origin: center left;
	        transform-origin: center left;
	/* color: #fff; */
	margin: 1rem;
}

/* .tile__img-main {
	width: 250px;
	height: 190.625px;
	-o-object-fit: cover;
	object-fit: cover;
} */

.tile__img {
	width: 250px;
	height: 190.625px;
	object-fit: cover;
}


.tile__details {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	top: 0;
	font-size: 10px;
	opacity: 0;
	background: linear-gradient(to top, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0) 100%);
	transition: 150ms opacity;
}
.tile__details:after,
.tile__details:before {
	content: '';
	position: absolute;
	top: 50%;
	left: 50%;
	display: #000;
}
.tile__details:after {
	margin-top: -25px;
	margin-left: -25px;
	width: 50px;
	height: 50px;
	border: 3px solid #ecf0f1;
	line-height: 50px;
	text-align: center;
	border-radius: 100%;
	background: rgba(0,0,0,0.5);
	z-index: 1;
}
.tile__details:before {
	content: '▶';
	color: #fff;
	left: 0;
	width: 100%;
	font-size: 30px;
	margin-left: 3px;
	margin-top: -22px;
	text-align: center;
	z-index: 2;
}
.tile:hover .tile__details {
	opacity: 1;
}

/* .tile__title {
position: absolute;
bottom: 0;
padding: 10px;
} */

.tile__title {
	position: absolute;
	bottom: -40px;
	/* padding: 10px;
	right: 0; */
	max-width: 250px;
	font-weight: bold;
	text-overflow: ellipsis;
	overflow: hidden;
	text-align: left;
	white-space: nowrap;
	/* text-shadow: 1px 2px 1px #000; */
}

.textshadow {
	text-shadow: 1px 2px 1px #000;
}

.row__inner:hover {
	/* transform: translate3d(-62.5px, 0, 0); */
}
.row__inner:hover .tile {
	/* opacity: 0.3; */

}
.row__inner:hover .tile:hover {
	-webkit-transform: scale(1.3);
	        transform: scale(1.3);
	opacity: 1;
	box-shadow: 0.3rem 0.4rem 0.4rem rgba(0, 0, 0, 0.4);
}
.tile:hover ~ .tile {
	-webkit-transform: translate3d(125px, 0, 0);
	        transform: translate3d(125px, 0, 0);
}

/* .footer-image{
	widht:30px;
	height: 30px;
}
ul {
	list-style-type: none;
}

li {
	display: inline-flex;
	margin: 20px;
	width: auto;
} */



/* .main-container {
	height: -moz-fit-content;
	height: fit-content;
	width: 100%;
	background-color: #FFF;
	bottom: 0px !important;
	position: absolute;
} */

.spaghetto-container {
	height: -moz-fit-content;
	height: -webkit-fit-content;
	height: fit-content;
	width: 100%;
	background-color: #FFF;
	/* bottom: 0px !important;
	position: absolute;
	box-shadow: 0px -4px 3px rgba(50, 50, 50, 0.15); */
}

#icons-container {
  display: flex;
  position: relative;
  justify-content: space-evenly;
}

/* .icon {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 1px solid white;
  margin-right: -15px;
} */
.icon {
	height: 40px;
	width: 100%;
	/* border: 1px solid white; */
  margin: 2px;
}
.icon-master {
	height: 60px;
	width: 400px;
	/* border: 1px solid white; */
}
.single-icon-container {
	height: 45px;
}

.login-image {

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 400px!important;
}

.front-slide {
  z-index: 999;
}

.paper {
  margin: 20px 0px 0px 0px;
  display: 'flex';
  flex-direction: 'column';
  align-items: 'center';

}

.paper-height {
  height: 500px;
}

.form-group {
  flex: 0 0 auto;
  flex-flow: row wrap;
  align-items: center;
  margin-bottom: 1rem;
  margin-left: 10%;
  margin-right: 10%;
}

.start-line {
  text-align: start;
  margin-bottom: 1rem;
}

/*
====================================
=== ANIMAZIONE IMMAGINI DI LOGIN ===
====================================
*/

@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  11.11% {
    opacity: 1;
  }
  33.33% {
    opacity: 1;
  }
  44.44% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  11.11% {
    opacity: 1;
  }
  33.33% {
    opacity: 1;
  }
  44.44% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.staticImg {
  position: relative;
  height: 329px;
  width: 100%;
  margin: 0 auto;
}

.fadein {
  position: relative;
  height: 329px;
  width: 100%;
  margin: 0 auto;
}
.fadein img {
  position: absolute;
  left: 0;
  right: 0;
  opacity: 0;
  -webkit-animation-name: fade;
          animation-name: fade;
  -webkit-animation-duration: 50s;
          animation-duration: 50s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;

}
.fadein img:nth-child(1) {
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}
.fadein img:nth-child(2) {
  -webkit-animation-delay: 11s;
          animation-delay: 11s;
}
.fadein img:nth-child(3) {
  -webkit-animation-delay: 22s;
          animation-delay: 22s;
}
.fadein img:nth-child(4) {
  -webkit-animation-delay: 33s;
          animation-delay: 33s;
}


.spaghetto-container {
	height: -moz-fit-content;
	height: -webkit-fit-content;
	height: fit-content;
	width: 100%;
	background-color: #FFF;
	/* bottom: 0px !important;
	position: absolute;
	box-shadow: 0px -4px 3px rgba(50, 50, 50, 0.15); */
}

#icons-container {
  display: flex;
  position: relative;
  justify-content: space-evenly;
}

/* .icon {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 1px solid white;
  margin-right: -15px;
} */
.icon {
	height: 40px;
	width: 100%;
	/* border: 1px solid white; */
  margin: 2px;
}
.icon-master {
	height: 60px;
	width: 400px;
	/* border: 1px solid white; */
}
.single-icon-container {
	height: 45px;
}


.login-image {

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 600px!important;
}

.front-slide {
  z-index: 999;
}

.paper {
  display: 'flex';
  flex-direction: 'column';
  align-items: 'center';

}

.paper-height {
  height: 500px;
}

.form-group {
  flex: 0 0 auto;
  flex-flow: row wrap;
  align-items: center;
  margin-bottom: 1rem;
  margin-left: 10%;
  margin-right: 10%;
}


/*
====================================
=== ANIMAZIONE IMMAGINI DI LOGIN ===
====================================
*/

@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  11.11% {
    opacity: 1;
  }
  33.33% {
    opacity: 1;
  }
  44.44% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  11.11% {
    opacity: 1;
  }
  33.33% {
    opacity: 1;
  }
  44.44% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.staticImg {
  position: relative;
  height: 329px;
  width: 100%;
  margin: 0 auto;
}

.fadein {
  position: relative;
  height: 369px;
  width: 100%;
  margin: 0 auto;
}
.fadein img {
  position: absolute;
  left: 0;
  right: 0;
  opacity: 0;
  -webkit-animation-name: fade;
          animation-name: fade;
  -webkit-animation-duration: 50s;
          animation-duration: 50s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;

}
.fadein img:nth-child(1) {
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}
.fadein img:nth-child(2) {
  -webkit-animation-delay: 11s;
          animation-delay: 11s;
}
.fadein img:nth-child(3) {
  -webkit-animation-delay: 22s;
          animation-delay: 22s;
}
.fadein img:nth-child(4) {
  -webkit-animation-delay: 33s;
          animation-delay: 33s;
}


.spaghetto-container {
	height: -moz-fit-content;
	height: -webkit-fit-content;
	height: fit-content;
	width: 100%;
	background-color: #FFF;
	/* bottom: 0px !important;
	position: absolute;
	box-shadow: 0px -4px 3px rgba(50, 50, 50, 0.15); */
}

#icons-container {
  display: flex;
  position: relative;
  justify-content: space-evenly;
}

/* .icon {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 1px solid white;
  margin-right: -15px;
} */
.icon {
	height: 40px;
	width: 100%;
	/* border: 1px solid white; */
  margin: 2px;
}
.icon-master {
	height: 60px;
	width: 400px;
	/* border: 1px solid white; */
}
.single-icon-container {
	height: 45px;
}

/* .iframe {
    margin-top: 140px;
    position: absolute;
    width: 100%;
    height: auto;
} */

.textarea {
  width: 100%;
}

.paddingTop {
  padding-top: 20px!important;
}

.paddingContainer {
  padding-top: 10px;
  padding-bottom: 20px;
}

.paddingLeft {
  padding-left: 5px;
}

.typogr-width {
  width: 95px;
}

.select-lang {
  padding-top: 20px;
  width: 80%;
}

.typogr-logo {
  width: 150px;
}


.ellips {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.divertical {
  height: 28px;
  margin: 4px;
}

.pad10 {
  padding: 10px;
}

.top20 {
  padding-top: 20px;
}

.bottom20{
  padding-bottom: 20px;
}

.bottom56 {
  padding-bottom: 56px;
}

.flexPad {
  padding: 2px 4px;
  display: flex;
  alignItems: center;
}

.flexInput {
  margin-left: 8px;
  flex: 1 1;
}

/* .MuiFormLabel-root {
padding-bottom: 16px!important;
color: "red"!important;
} */

/* .MuiTextField-root > label {
background-color: orange;
color: red;
} */

.MuiTextField-root > .MuiFormLabel-root.Mui-focused {
  padding-top: 8px;
}


.resourcesbody {
    margin-top: 50px;
    margin-bottom: 10px;
    margin-right: 10px;
    margin-left: 10px;
}

.paperdivres{
 padding: 3px 3px;
 display: flex;
 width: 300;
}

.dividerinputres {
     height: 20;
     margin: 4;
}
.paperdivres{
  padding: 3px 3px;
  display: flex;
  width: 300;
}

.dividerinputres {
  height: 20;
  margin: 4;
}

.cardstyle {
  /* width: 40vmin; */
  /* height: 47vmin; */
  /* width: 250px; */
  /* height: 265px; */
  /* float: left; */
  /* margin: 5px 5px 5px 5px; */

  margin: 15px;
  width: 300px;
  height: 290px;
  box-shadow: 2px 2px 2px #888888!important;
  padding:0!important;
  float: left;
}

.cardheaderstyle {
  /* width: 30vmin; */
  /* height: 7vmin; */
  /* margin-top: 3px; */
  /* >>>>>>>>>> aggiunta */
}

.cardmediastyle {
  /* width: 40vmin; */
  width: auto;
  /* height: 27vmin; */
  height: 180px;
  /* margin-top: 10px;  */
  /* object-fit: cover !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important; */

}

.cardcontentstyle {
  width: 30vmin;
  height: 2vmin;
}

.cardsubtextstyle {
  font-size: 11px;
}

.cardtextstyle {
  /* width: 15vmin; */
  width: 140px;
  /* min-width: 15vmin; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cardtextstylefooter {
  /* width: 30vmin; */
  /* min-width: 20vmin; */
  /* min-width: 16vmin; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.carddesc {
  width:35%;
}

.cardshare {
  width: 100%;
}

.actionarea {
  width: 400px;
  height: 40px;
}
.gridcontainerwithmap {
  /* margin: 30px 0px 15px 5px; */
  margin-top: 28px;
}

.gridcontainer {
  width: 100vw;
  /* margin: 30px 5px 15px 5px; */
  /* padding: 1vh 0px 1vh 6%; */
  margin-top: 50px;
}

.cardcontainer {
  margin: 0px 1vmin 0px 1vmin;
}

.appbarstyle{
  margin: 70px 0px 0px 0px;
}

.mapcontainer{
  margin: 0px 0px 0px 0px;
  height: 80vh;
  width: 74vw;
  position: fixed;
  margin: 0;
}


.rights-img-RM {
  width: 62px;
  bottom: 1px;
  top:5px;
  position: relative;
}


.type-find {
  position: absolute;
  z-index: 1;
  margin-top: 15px;
  margin-left: 5px;
}


.public-rights-img {
  width: 32px;
  position: relative;
}



.backup {
  position: absolute;
  z-index: 1;
  padding-top: 0px;
  margin-left: 5px;
}


.btn-dotted {
  border: 2px dotted;
  border-radius: 50px;
  background-color: #e8dada;
}

.selectright{
    margin: 0px 0px 0px 0px;
    width: 20vw;
    margin: 0;
    display:inline-block;

  }
  .selectmedialanguage{

    width: 20vw;
    margin: 0;

  }

  .selectlanguagedata{
    margin: 0px 0px 0px 0px;
    width: 20vw;
    margin: 0;
  }

  #myImage {
    opacity: 0.5;
    filter: alpha(opacity=50); /* msie */
}

.descriptionBlock{
  border-radius: 25px;
  border: 1px solid grey;
  padding: 10px;
  margin: 5px;

  }
  .selectright1{
    margin: 0px 0px 0px 0px;
    width: 15vw;
    margin: 0;
    display:inline-block;
    }

.gridmargin {
	margin: 10px 0px 10px 0px;
}
  .selectmedialanguage{

    width: 20vw;
    margin: 0;

  }

  .selectlanguagedata{
    margin: 0px 0px 0px 0px;
    width: 20vw;
    margin: 0;
  }

  #myImage {
    opacity: 0.5;
    filter: alpha(opacity=50); /* msie */
}

.descriptionBlock{
  border-radius: 25px;
  border: 1px solid grey;
  padding: 10px;
  margin: 5px;

  }

.cardmap {
    /* Add shadows to create the "card" effect */
   width: 100%;
   padding: 2px 5px;
   overflow: hidden;
   height: 100%
}

.mapbutton{
     display: block;
     width: 100%;
     border: none;
     background-color: #5a6199;
     color: white;
     padding: 14px 28px;
     font-size: 16px;
     cursor: pointer;
     text-align: center;
}

 .mapbutton:hover{
    background-color: #ddd;
    color: black;
 }

.mediacontent {
  width: 45%;
  object-fit: contain;
  cursor: pointer;
}

.info-button {
  /* text-align: center; */
  /* margin: 7%; */
  /* width: 350px; */
  /* position: relative;  */
}

.info-button:hover .info-overlay {
  -webkit-transform: scale(1);
}

.info-overlay {
  position: absolute;
  width: 250px;
  height: 95%;
  overflow-y: auto;
  /* line-height: 284px; */
  /* top: 5px; */
  /* color: white; */
  /* font-family: sans-serif; */
  /* font-size: 20px; */
  /* font-weight: bold; */
  /* left: 6px; */
  /* text-align: center; */
  transition: all 0.2s ease-in-out;
  -webkit-transform: scale(0);
  border: 1px solid white;
  background-color: #ffffffb0;
  /* border-radius: 15px; */
  padding: 8px;
  box-shadow: 9px 9px 16px rgba(189, 189, 189, 0.6), -9px -9px 16px rgba(255, 255, 255, 0.5);
  word-wrap: break-word;
  display: inline-flex;
}

.info-sub {
  text-align: center;
  position: absolute;
  top: -7px;
  z-index: 3;
  /* font-size: 24px; */
  /* font-weight: bold; */
  /* background: rgba(36, 18, 2, 0.65); */
  /* color: white; */
  width: 95%;
}

.rights-img {
	width: 70px;
	bottom: 6px;
	margin-left: 10px;
	position: relative;
}

.fsize25 {
  font-size: 2.5rem!important;
}

.qrcode {
  object-fit: contain;
  margin: 232px;
  display: block;
  position: absolute;
  display: relative;
  margin-left: 30%;
  margin-right: auto;
  margin-top: 5%;
  width: 40%;
}

.document-div{
  /* position: absolute; */
  width: 100%;
  height: 100%;
  margin: auto;
  overflow: scroll
}

.document-div-inner{
  margin-top: 100px;
  margin-left : 100px;
  margin-right : 100px;
  text-align:center;
  position:relative;


}

.positioncloud{
  bottom: 15px;
  color:black;
}


.fsize35{
  font-size: 3.5rem!important;
}

.rights-img-rm{
  width: 100px;

}

.gridmargin{
  margin: 10px 10px 10px 0px;
}



.box-wrap{
  display:flex!important;
  align-items:center;
  justify-content:center;
  margin:25px;
}
/* .box{
  padding:5px;
  margin:2%;
  background:white;
  width:30%;
  border:1px solid #d6d6d6;
  box-shadow:0 2px 3px 0px rgba(0,0,0,0.25);
  border-radius:3px;
  transition:.2s all;
} */
.box {
	/* padding: 10px; */
	margin: -20px auto -20px auto;
	/* background: white; */
	width: 25%;
	border: 1px solid #d6d6d6;
	box-shadow: 0 2px 3px 0px rgba(0,0,0,0.25);
	border-radius: 3px;
	transition: .2s all;
}
.box-wrap:hover .box{
  -webkit-filter:blur(3px);
          filter:blur(3px);
  opacity:.5;
  -webkit-transform: scale(.98);
          transform: scale(.98);
  box-shadow:none;
}
.box-wrap:hover .box:hover{
  -webkit-transform:scale(1);
          transform:scale(1);
  -webkit-filter:blur(0px);
          filter:blur(0px);
  opacity:1;
  box-shadow:0 8px 20px 0px rgba(0,0,0,0.125);
}

div.ellipsed {
  white-space: nowrap;
  width: 99%;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 15px
}

.ellipsedTitle {
  white-space: nowrap;
  width: 99%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.MuiDivider-root-353 {
	background-color: rgba(0, 0, 0, 0.04)!important;
}

.pad-05 {
	padding:2px;
}

.pad-5 {
	padding:5px;
}

.fsize15 {
  font-size: 2.2rem!important;
}

.prev-bright {
  text-align:right;
  font-weight: 900
}

.prev-bleft {
  text-align:left;
  /* font-weight: 900 */
}

/* .focus-only:focus {
outline: 1px solid black;
}

.focus-visible-only:focus-visible {
outline: 4px dashed darkorange;
} */


.fontnormal {
  font-size: 100%;
}

.fontlarge {
  font-size: 107%;
}

.fontxlarge {
  font-size: 114%;
}

.fontxxlarge {
  font-size: 120%;
}

/* ===== MENU HEADER ====== */

.logo {
  /* margin: 5px 0px 0px 0px; */
  object-fit: contain;
  /* height: 5vh; */
  height: 55px;
}
.head-animation {
  font-family: 'Raleway', Arial, sans-serif;
  text-align: left;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 0.1em;
}
.head-animation * {
  box-sizing: border-box;
  transition: all 0.35s ease;
}
.head-animation li {
  display: inline-block;
  list-style: outside none none;
  margin: 0 2em;
  padding: 0;
}
.head-animation a {
  display: inline-block;
  padding: 0.1em 17px;
  /* color: limoncello; */
  position: relative;
  letter-spacing: 1px;
  text-decoration: none;
}
.head-animation a:before {
  left: 20%;
  right: 20%;
  top: 50%;
  content: '';
  border-left: 12px solid #4791db;
  border-right: 12px solid #4791db;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 3px;
  opacity: 0;
  position: absolute;
  transition: all 0.35s ease;
}
.head-animation a:hover,
.head-animation .current a {
  color: #4791db;
}
.head-animation a:hover:before,
.head-animation .current a:before {
  left: 0;
  right: 0;
  opacity: 1;
}



/* ===== IFRAME MENU SUB-HEADER ====== */
.iframe-animation {
  font-family: 'Raleway', Arial, sans-serif;
  text-align: center;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  line-height: 0.1em;
}
.iframe-animation * {
  box-sizing: border-box;
  transition: all 0.35s ease;
}
.iframe-animation li {
  display: inline-block;
  list-style: outside none none;
  margin: 0 1em;
  padding: 0;
}
.iframe-animation a {
  padding: 0 0.8em;
  /* margin: 0.2em 0; */
  display: block;
  /* color: rgba(255, 255, 255, 0.5); */
  position: relative;
  text-decoration: none;
}
.iframe-animation a:before,
.iframe-animation a:after {
  height: 14px;
  width: 14px;
  position: absolute;
  content: '';
  transition: all 0.35s ease;
  opacity: 0;
}
.iframe-animation a:before {
  left: 0;
  top: 0;
  border-left: 3px solid #ffffff;
  border-top: 3px solid #ffffff;
  -webkit-transform: translate(100%, 50%);
  transform: translate(100%, 50%);
}
.iframe-animation a:after {
  right: 0;
  bottom: 0;
  border-right: 3px solid #ffffff;
  border-bottom: 3px solid #ffffff;
  -webkit-transform: translate(-100%, -50%);
  transform: translate(-100%, -50%);
}
.iframe-animation a:hover,
.iframe-animation .current a {
  /* color: #ffffff; */
}
.iframe-animation a:hover:before,
.iframe-animation .current a:before,
.iframe-animation a:hover:after,
.iframe-animation .current a:after {
  -webkit-transform: translate(0%, 0%);
  transform: translate(0%, 0%);
  opacity: 1;
}

.avatarSh {
  width:40px;
  height:40px;
  box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12);
}

.info {
  padding:10px;
  width: auto;
}

.info-button {
  padding: 25px 0px 0px 0px;
  text-align: start;
}

.container-info {
  display: flex;
}
.container-info > div {
  flex: 1 1; /*grow*/
}


.left-column
{
  float:left;
  width:35%;
}

.right-column
{
  float:right;
  width:65%;
}

/*
.center-column
{
margin:auto;
width:30%;
}
*/

.cultural-space {
  width:100%;
  display: inline-flex;
}

.bold {
  font-weight: 900!important;
}

.left-close {
  margin: -5px 0 -30px 88%;
}



.cardstyle-sr {
  margin: 15px;
  width: 300px;
  height: 300px;
  box-shadow: 2px 2px 2px #888888 !important;
  padding: 0 !important;
  float: left;
}

.cardcontainer {
  margin: 0px 1vmin 0px 1vmin;
}

.appbarstyle{
  margin: 70 0px 0px 0px;
}

.mapcontainer{
  margin: 0px 0px 0px 0px;
  height: 80vh;
  width: 74vw;
  position: fixed;
  margin: 0;
}


.btn-dotted {
  border: 2px dotted;
  border-radius: 50px;
  background-color: #e8dada;
}

.domain-image{
  width: 36px;
  max-height: 26px;
  top:10px;
  position: relative;
}

.gridcontainerwithmap {
  /* margin: 30px 0px 15px 5px; */
  margin-top: 28px;
}

.gridcontainer {
  width: 100vw;
  /* margin: 30px 5px 15px 5px; */
  /* padding: 1vh 0px 1vh 6%; */
  margin-top: 50px;
}

.cardmap {
    /* Add shadows to create the "card" effect */
   width: 100%;
   padding: 2px 5px;
   overflow: hidden;
   height: 100%
}

.mapbutton{
     display: block;
     width: 100%;
     border: none;
     background-color: #5a6199;
     color: white;
     padding: 14px 28px;
     font-size: 16px;
     cursor: pointer;
     text-align: center;
}

 .mapbutton:hover{
    background-color: #ddd;
    color: black;
 }

.paperdivres{
    padding: 3px 3px;
    display: flex;
    width: 300;
  }

  .dividerinputres {
    height: 20;
    margin: 4;
  }

  .cardstyle {
    /* width: 40vmin; */
    /* height: 47vmin; */
    /* width: 250px; */
    /* height: 265px; */
    /* float: left; */
    /* margin: 5px 5px 5px 5px; */

    margin: 15px;
    width: 300px;
    height: 290px;
    box-shadow: 2px 2px 2px #888888!important;
    padding:0!important;
    float: left;
  }

  .cardheaderstyle {
    /* width: 30vmin; */
    /* height: 7vmin; */
    /* margin-top: 3px; */
    /* >>>>>>>>>> aggiunta */
  }

  .cardmediastyle {
    /* width: 40vmin; */
    width: auto;
    /* height: 27vmin; */
    height: 180px;
    /* margin-top: 10px;  */
    /* object-fit: cover !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important; */

  }

  .cardcontentstyle {
    width: 30vmin;
    height: 2vmin;
  }

  .cardsubtextstyle {
    font-size: 11px;
  }

  .cardtextstyle {
    /* width: 15vmin; */
    width: 140px;
    /* min-width: 15vmin; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .cardtextstylefooter {
    /* width: 30vmin; */
    /* min-width: 20vmin; */
    /* min-width: 16vmin; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .carddesc {
    width:35%;
  }

  .cardshare {
    width: 100%;
  }

  .actionarea {
    width: 400px;
    height: 40px;
  }
  .gridcontainerwithmap {
    /* margin: 30px 0px 15px 5px; */
    margin-top: 28px;
  }

  .gridcontainer {
    width: 100vw;
    /* margin: 30px 5px 15px 5px; */
    /* padding: 1vh 0px 1vh 6%; */
    margin-top: 50px;
  }

  .cardcontainer {
    margin: 0px 1vmin 0px 1vmin;
  }

  .appbarstyle{
    margin: 70px 0px 0px 0px;
  }

  .mapcontainer{
    margin: 0px 0px 0px 0px;
    height: 80vh;
    width: 74vw;
    position: fixed;
    margin: 0;
  }


  .rights-img-RM {
    width: 62px;
    bottom: 1px;
    top:5px;
    position: relative;
  }


  .type-find {
    position: absolute;
    z-index: 1;
    margin-top: 15px;
    margin-left: 5px;
  }


  .public-rights-img {
    width: 32px;
    position: relative;
  }



  .backup {
    position: absolute;
    z-index: 1;
    padding-top: 0px;
    margin-left: 5px;
  }

  .btn-dotted {
  border: 2px dotted;
  border-radius: 50px;
  background-color: #e8dada;
}

.selectright{
    margin: 0px 0px 0px 0px;
    width: 20vw;
    margin: 0;
    display:inline-block;    
    
  }
  .selectmedialanguage{

    width: 20vw;
    margin: 0;

  }

  .selectlanguagedata{
    margin: 0px 0px 0px 0px;
    width: 20vw;
    margin: 0;
  }

  #myImage {
    opacity: 0.5;
    filter: alpha(opacity=50); /* msie */
}

.descriptionBlock{
  border-radius: 25px;
  border: 1px solid grey;
  padding: 10px;
  margin: 5px;

  }
  .selectright1{
    margin: 0px 0px 0px 0px;
    width: 15vw;
    margin: 0;
    display:inline-block;
    }

.gridmargin {
	margin: 10px 0px 10px 0px;
}
  .selectmedialanguage{

    width: 20vw;
    margin: 0;

  }

  .selectlanguagedata{
    margin: 0px 0px 0px 0px;
    width: 20vw;
    margin: 0;
  }

  #myImage {
    opacity: 0.5;
    filter: alpha(opacity=50); /* msie */
}

.descriptionBlock{
  border-radius: 25px;
  border: 1px solid grey;
  padding: 10px;
  margin: 5px;

  }

.cardmap {
    /* Add shadows to create the "card" effect */
   width: 100%;
   padding: 2px 5px;
   overflow: hidden;
   height: 100%
}

.mapbutton{
     display: block;
     width: 100%;
     border: none;
     background-color: #5a6199;
     color: white;
     padding: 14px 28px;
     font-size: 16px;
     cursor: pointer;
     text-align: center;
}

 .mapbutton:hover{
    background-color: #ddd;
    color: black;
 }

.mediacontent {
  width: 45%;
  object-fit: contain;
  cursor: pointer;
}

.info-button {
  /* text-align: center; */
  /* margin: 7%; */
  /* width: 350px; */
  /* position: relative;  */
}

.info-button:hover .info-overlay {
  -webkit-transform: scale(1);
}

.info-overlay {
  position: absolute;
  width: 250px;
  height: 95%;
  overflow-y: auto;
  /* line-height: 284px; */
  /* top: 5px; */
  /* color: white; */
  /* font-family: sans-serif; */
  /* font-size: 20px; */
  /* font-weight: bold; */
  /* left: 6px; */
  /* text-align: center; */
  transition: all 0.2s ease-in-out;
  -webkit-transform: scale(0);
  border: 1px solid white;
  background-color: #ffffffb0;
  /* border-radius: 15px; */
  padding: 8px;
  box-shadow: 9px 9px 16px rgba(189, 189, 189, 0.6), -9px -9px 16px rgba(255, 255, 255, 0.5);
  word-wrap: break-word;
  display: inline-flex;
}

.info-sub {
  text-align: center;
  position: absolute;
  top: -7px;
  z-index: 3;
  /* font-size: 24px; */
  /* font-weight: bold; */
  /* background: rgba(36, 18, 2, 0.65); */
  /* color: white; */
  width: 95%;
}

.rights-img {
	width: 70px;
	bottom: 6px;
	margin-left: 10px;
	position: relative;
}

.fsize25 {
  font-size: 2.5rem!important;
}

.qrcode {
  object-fit: contain;
  margin: 232px;
  display: block;
  position: absolute;
  display: relative;
  margin-left: 30%;
  margin-right: auto;
  margin-top: 5%;
  width: 40%;
}

.document-div{
  /* position: absolute; */
  width: 100%;
  height: 100%;
  margin: auto;
  overflow: scroll
}

.document-div-inner{
  margin-top: 100px;
  margin-left : 100px;
  margin-right : 100px;
  text-align:center;
  position:relative;


}

.positioncloud{
  bottom: 15px;
  color:black;
}


.fsize35{
  font-size: 3.5rem!important;
}

.rights-img-rm{
  width: 100px;

}

.gridmargin{
  margin: 10px 10px 10px 0px;
}



.box-wrap{
  display:flex!important;
  align-items:center;
  justify-content:center;
  margin:25px;
}
/* .box{
  padding:5px;
  margin:2%;
  background:white;
  width:30%;
  border:1px solid #d6d6d6;
  box-shadow:0 2px 3px 0px rgba(0,0,0,0.25);
  border-radius:3px;
  transition:.2s all;
} */
.box {
	/* padding: 10px; */
	margin: -20px auto -20px auto;
	/* background: white; */
	width: 25%;
	border: 1px solid #d6d6d6;
	box-shadow: 0 2px 3px 0px rgba(0,0,0,0.25);
	border-radius: 3px;
	transition: .2s all;
}
.box-wrap:hover .box{
  -webkit-filter:blur(3px);
          filter:blur(3px);
  opacity:.5;
  -webkit-transform: scale(.98);
          transform: scale(.98);
  box-shadow:none;
}
.box-wrap:hover .box:hover{
  -webkit-transform:scale(1);
          transform:scale(1);
  -webkit-filter:blur(0px);
          filter:blur(0px);
  opacity:1;
  box-shadow:0 8px 20px 0px rgba(0,0,0,0.125);
}

div.ellipsed {
  white-space: nowrap;
  width: 99%;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 15px
}

.ellipsedTitle {
  white-space: nowrap;
  width: 99%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.MuiDivider-root-353 {
	background-color: rgba(0, 0, 0, 0.04)!important;
}

.pad-05 {
	padding:2px;
}

.pad-5 {
	padding:5px;
}

.fsize15 {
  font-size: 2.2rem!important;
}

.prev-bright {
  text-align:right;
  font-weight: 900
}

.prev-bleft {
  text-align:left;
  /* font-weight: 900 */
}

.paperdivres{
  padding: 3px 3px;
  display: flex;
  width: 300;
}

.dividerinputres {
  height: 20;
  margin: 4;
}

.cardstyle {
  /* width: 40vmin; */
  /* height: 47vmin; */
  /* width: 250px; */
  /* height: 265px; */
  /* float: left; */
  /* margin: 5px 5px 5px 5px; */

  margin: 15px;
  width: 300px;
  height: 290px;
  box-shadow: 2px 2px 2px #888888!important;
  padding:0!important;
  float: left;
}

.cardheaderstyle {
  /* width: 30vmin; */
  /* height: 7vmin; */
  /* margin-top: 3px; */
  /* >>>>>>>>>> aggiunta */
}

.cardmediastyle {
  /* width: 40vmin; */
  width: auto;
  /* height: 27vmin; */
  height: 180px;
  /* margin-top: 10px;  */
  /* object-fit: cover !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important; */

}

.cardcontentstyle {
  width: 30vmin;
  height: 2vmin;
}

.cardsubtextstyle {
  font-size: 11px;
}

.cardtextstyle {
  /* width: 15vmin; */
  width: 140px;
  /* min-width: 15vmin; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cardtextstylefooter {
  /* width: 30vmin; */
  /* min-width: 20vmin; */
  /* min-width: 16vmin; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.carddesc {
  width:35%;
}

.cardshare {
  width: 100%;
}

.actionarea {
  width: 400px;
  height: 40px;
}
.gridcontainerwithmap {
  /* margin: 30px 0px 15px 5px; */
  margin-top: 50px;
}

.gridcontainer {
  width: 100vw;
  /* margin: 30px 5px 15px 5px; */
  /* padding: 1vh 0px 1vh 6%; */
  margin-top: 50px;
}

.cardcontainer {
  margin: 0px 1vmin 0px 1vmin;
}

.appbarstyle{
  margin: 70px 0px 0px 0px;
}

.mapcontainer{
  margin: 0px 0px 0px 0px;
  height: 80vh;
  width: 74vw;
  position: fixed;
  margin: 0;
}


.rights-img-RM {
  width: 62px;
  bottom: 1px;
  top:5px;
  position: relative;
}


.type-find {
  position: absolute;
  z-index: 1;
  margin-top: 15px;
  margin-left: 5px;
}


.public-rights-img {
  width: 32px;
  position: relative;
}



.backup {
  position: absolute;
  z-index: 1;
  padding-top: 0px;
  margin-left: 5px;
}


.btn-dotted {
  border: 2px dotted;
  border-radius: 50px;
  background-color: #e8dada;
}

.selectright{
  margin: 0px 0px 0px 0px;
  width: 20vw;
  margin: 0;
  display:inline-block;

}
.selectmedialanguage{

  width: 20vw;
  margin: 0;

}

.selectlanguagedata{
  margin: 0px 0px 0px 0px;
  width: 20vw;
  margin: 0;
}

#myImage {
  opacity: 0.5;
  filter: alpha(opacity=50); /* msie */
}

.descriptionBlock{
  border-radius: 25px;
  border: 1px solid grey;
  padding: 10px;
  margin: 5px;

}
.selectright1{
  margin: 0px 0px 0px 0px;
  width: 9vw;
  margin: 0;
  display:inline-block;
}
.gridmargin2{
  margin: 10px 0px 10px 0px;
}

.gridmargin {
	margin: 10px 0px 10px 0px;
}
  .selectmedialanguage{

    width: 20vw;
    margin: 0;

  }

  .selectlanguagedata{
    margin: 0px 0px 0px 0px;
    width: 20vw;
    margin: 0;
  }

  #myImage {
    opacity: 0.5;
    filter: alpha(opacity=50); /* msie */
}

.descriptionBlock{
  border-radius: 25px;
  border: 1px solid grey;
  padding: 10px;
  margin: 5px;

  }

.cardmap {
    /* Add shadows to create the "card" effect */
   width: 100%;
   padding: 2px 5px;
   overflow: hidden;
   height: 100%;
   color: #000;
}


.cardmap img {

   width: 18px;
   height: 18px;
}


.mapbutton{
     display: block;
     width: 100%;
     border: none;
     background-color: #5a6199;
     color: white;
     padding: 14px 28px;
     font-size: 16px;
     cursor: pointer;
     text-align: center;
}

 .mapbutton:hover{
    background-color: #ddd;
    color: black;
 }

.mediacontent {
  width: 45%;
  object-fit: contain;
  cursor: pointer;
}

.info-button {
  /* text-align: center; */
  /* margin: 7%; */
  /* width: 350px; */
  /* position: relative;  */
}

.info-button:hover .info-overlay {
  -webkit-transform: scale(1);
}

.info-overlay {
  position: absolute;
  width: 250px;
  height: 95%;
  overflow-y: auto;
  /* line-height: 284px; */
  /* top: 5px; */
  /* color: white; */
  /* font-family: sans-serif; */
  /* font-size: 20px; */
  /* font-weight: bold; */
  /* left: 6px; */
  /* text-align: center; */
  transition: all 0.2s ease-in-out;
  -webkit-transform: scale(0);
  border: 1px solid white;
  background-color: #ffffffb0;
  /* border-radius: 15px; */
  padding: 8px;
  box-shadow: 9px 9px 16px rgba(189, 189, 189, 0.6), -9px -9px 16px rgba(255, 255, 255, 0.5);
  word-wrap: break-word;
  display: inline-flex;
}

.info-sub {
  text-align: center;
  position: absolute;
  top: -7px;
  z-index: 3;
  /* font-size: 24px; */
  /* font-weight: bold; */
  /* background: rgba(36, 18, 2, 0.65); */
  /* color: white; */
  width: 95%;
}

.rights-img {
	width: 70px;
	bottom: 6px;
	margin-left: 10px;
	position: relative;
}

.fsize25 {
  font-size: 2.5rem!important;
}

.qrcode {
  object-fit: contain;
  margin: 232px;
  display: block;
  position: absolute;
  display: relative;
  margin-left: 30%;
  margin-right: auto;
  margin-top: 5%;
  width: 40%;
}

.document-div2{
  /* position: absolute; */
  width: 100%;
  height: 100%;
  margin: auto;
  overflow: scroll
}

.document-div-inner{
  margin-top: 100px;
  margin-left : 100px;
  margin-right : 100px;
  text-align:center;
  position:relative;


}

.positioncloud{
  bottom: 15px;
  color:black;
}


.fsize35{
  font-size: 3.5rem!important;
}

.rights-img-rm{
  width: 100px;

}

.gridmargin{
  margin: 10px 10px 10px 0px;
}



.box-wrap{
  display:flex!important;
  align-items:center;
  justify-content:center;
  margin:25px;
}
/* .box{
  padding:5px;
  margin:2%;
  background:white;
  width:30%;
  border:1px solid #d6d6d6;
  box-shadow:0 2px 3px 0px rgba(0,0,0,0.25);
  border-radius:3px;
  transition:.2s all;
} */
.box {
	/* padding: 10px; */
	margin: -20px auto -20px auto;
	/* background: white; */
	width: 25%;
	border: 1px solid #d6d6d6;
	box-shadow: 0 2px 3px 0px rgba(0,0,0,0.25);
	border-radius: 3px;
	transition: .2s all;
}
.box-wrap:hover .box{
  -webkit-filter:blur(3px);
          filter:blur(3px);
  opacity:.5;
  -webkit-transform: scale(.98);
          transform: scale(.98);
  box-shadow:none;
}
.box-wrap:hover .box:hover{
  -webkit-transform:scale(1);
          transform:scale(1);
  -webkit-filter:blur(0px);
          filter:blur(0px);
  opacity:1;
  box-shadow:0 8px 20px 0px rgba(0,0,0,0.125);
}

div.ellipsed {
  white-space: nowrap;
  width: 99%;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 15px
}

.ellipsedTitle {
  white-space: nowrap;
  width: 99%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.MuiDivider-root-353 {
	background-color: rgba(0, 0, 0, 0.04)!important;
}

.pad-05 {
	padding:2px;
}

.pad-5 {
	padding:5px;
}

.fsize15 {
  font-size: 2.2rem!important;
}

.prev-bright {
  text-align:right;
  font-weight: 900
}

.prev-bleft {
  text-align:left;
  /* font-weight: 900 */
}

