

.cardstyle-sr {
  margin: 15px;
  width: 300px;
  height: 300px;
  box-shadow: 2px 2px 2px #888888 !important;
  padding: 0 !important;
  float: left;
}

.cardcontainer {
  margin: 0px 1vmin 0px 1vmin;
}

.appbarstyle{
  margin: 70 0px 0px 0px;
}

.mapcontainer{
  margin: 0px 0px 0px 0px;
  height: 80vh;
  width: 74vw;
  position: fixed;
  margin: 0;
}


.btn-dotted {
  border: 2px dotted;
  border-radius: 50px;
  background-color: #e8dada;
}

.domain-image{
  width: 36px;
  max-height: 26px;
  top:10px;
  position: relative;
}

.gridcontainerwithmap {
  /* margin: 30px 0px 15px 5px; */
  margin-top: 28px;
}

.gridcontainer {
  width: 100vw;
  /* margin: 30px 5px 15px 5px; */
  /* padding: 1vh 0px 1vh 6%; */
  margin-top: 50px;
}
