
.heading {
	font-size: 16px;
	text-align: center;
	padding: 0px 0 70px 0;
}
.gallery {
	display: flex;
	flex-wrap: wrap;
	/* Compensate for excess margin on outer gallery flex items */
	margin: -1rem -1rem;
	padding-bottom: 30px;
	width: 90%;
}

.gallery-image {
	display: block;
	width: 100%;
	height: 230px;
	object-fit: cover;
	transition: transform 200ms ease-out;
	cursor:pointer;

}

.gallery-image:hover {
	/* transform: scale(1.65); */
}

.gallery-item {
	/* Minimum width of 24rem and grow to fit available space */
	flex: 1 0 28rem;
	/* Margin value should be half of grid-gap value as margins on flex items don't collapse */
	margin: 1rem;
	box-shadow: 0.3rem 0.4rem 0.4rem rgba(0, 0, 0, 0.4);
	overflow: hidden;

}

.title {
	position: static;
	max-width: 285px;
	height: auto;
	text-transform: uppercase;
	font-weight: bold;
	text-overflow: ellipsis;
	overflow: hidden;
	text-align: left;
	white-space: nowrap;
}

div.disabled
{
	pointer-events: none;
	/* for "disabled" effect */
	opacity: 0.3;
	/* background: #000; */
}


/*
==========================animaz mattonelle================================
h t t p s : / / c o d e p e n . i o / j o s h h u n t / p e n / L V Q Z R a
===========================================================================
*/


.contain {
	width: 100%;
	align-content: center;
	display: flex;
	text-align: center;
}

.row {
	width: 100%;
	margin-top: -80px;
	height: 380px;
	overflow-block: hidden;
}



.row2 {
	width: 100%;
	margin-top: -80px;
	height: 380px;
	/* overflow-block: hidden; */
}


.row__inner {
	transition: 150ms transform;
	font-size: 0;
	white-space: nowrap;
	margin: 58.3125px 0;
	padding-bottom: 10px;
}

/* .tile-main {
	position: relative;
	display: inline-block;
	width: 250px;
	height: 190.625px;
	margin-right: 10px;
	font-size: 20px;
	cursor: pointer;
	transition: 150ms all;
	transform-origin: center left;
	margin: 1rem;
} */

.tile {
	position: relative;
	display: inline-block;
	width: 250px;
	height: 190.625px;
	margin-right: 10px;
	font-size: 20px;
	cursor: pointer;
	transition: 150ms all;
	transform-origin: center left;
	/* color: #fff; */
	margin: 1rem;
}

/* .tile__img-main {
	width: 250px;
	height: 190.625px;
	-o-object-fit: cover;
	object-fit: cover;
} */

.tile__img {
	width: 250px;
	height: 190.625px;
	-o-object-fit: cover;
	object-fit: cover;
}


.tile__details {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	top: 0;
	font-size: 10px;
	opacity: 0;
	background: linear-gradient(to top, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0) 100%);
	transition: 150ms opacity;
}
.tile__details:after,
.tile__details:before {
	content: '';
	position: absolute;
	top: 50%;
	left: 50%;
	display: #000;
}
.tile__details:after {
	margin-top: -25px;
	margin-left: -25px;
	width: 50px;
	height: 50px;
	border: 3px solid #ecf0f1;
	line-height: 50px;
	text-align: center;
	border-radius: 100%;
	background: rgba(0,0,0,0.5);
	z-index: 1;
}
.tile__details:before {
	content: '▶';
	color: #fff;
	left: 0;
	width: 100%;
	font-size: 30px;
	margin-left: 3px;
	margin-top: -22px;
	text-align: center;
	z-index: 2;
}
.tile:hover .tile__details {
	opacity: 1;
}

/* .tile__title {
position: absolute;
bottom: 0;
padding: 10px;
} */

.tile__title {
	position: absolute;
	bottom: -40px;
	/* padding: 10px;
	right: 0; */
	max-width: 250px;
	font-weight: bold;
	text-overflow: ellipsis;
	overflow: hidden;
	text-align: left;
	white-space: nowrap;
	/* text-shadow: 1px 2px 1px #000; */
}

.textshadow {
	text-shadow: 1px 2px 1px #000;
}

.row__inner:hover {
	/* transform: translate3d(-62.5px, 0, 0); */
}
.row__inner:hover .tile {
	/* opacity: 0.3; */

}
.row__inner:hover .tile:hover {
	transform: scale(1.3);
	opacity: 1;
	box-shadow: 0.3rem 0.4rem 0.4rem rgba(0, 0, 0, 0.4);
}
.tile:hover ~ .tile {
	transform: translate3d(125px, 0, 0);
}

/* .footer-image{
	widht:30px;
	height: 30px;
}
ul {
	list-style-type: none;
}

li {
	display: inline-flex;
	margin: 20px;
	width: auto;
} */



/* .main-container {
	height: -moz-fit-content;
	height: fit-content;
	width: 100%;
	background-color: #FFF;
	bottom: 0px !important;
	position: absolute;
} */

.spaghetto-container {
	height: -moz-fit-content;
	height: fit-content;
	width: 100%;
	background-color: #FFF;
	/* bottom: 0px !important;
	position: absolute;
	box-shadow: 0px -4px 3px rgba(50, 50, 50, 0.15); */
}

#icons-container {
  display: flex;
  position: relative;
  justify-content: space-evenly;
}

/* .icon {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 1px solid white;
  margin-right: -15px;
} */
.icon {
	height: 40px;
	width: 100%;
	/* border: 1px solid white; */
  margin: 2px;
}
.icon-master {
	height: 60px;
	width: 400px;
	/* border: 1px solid white; */
}
.single-icon-container {
	height: 45px;
}
